import React from "react";

const Cookies = () => {
  return (
    <main className="trfan-privacy">
      <section className="trfan-privacy__body">
        <h1>tr-fantasy-top.com için Çerez Politikası</h1>
        <p className="t-17">
          Bu, tr-fantasy-top.com adresinden erişilebilen tr-fantasy-top.com için
          Çerez Politikasıdır.
        </p>
        <h2>Çerezler Nedir</h2>
        <p className="t-17">
          Hemen hemen tüm profesyonel web sitelerinde olduğu gibi, bu site de
          deneyiminizi iyileştirmek amacıyla bilgisayarınıza indirilen küçük
          dosyalar olan çerezleri kullanır. Bu sayfa, çerezlerin hangi bilgileri
          topladığını, bunları nasıl kullandığımızı ve neden bu çerezleri bazen
          saklamamız gerektiğini açıklar. Ayrıca bu çerezlerin saklanmasını
          nasıl engelleyebileceğinizi de paylaşacağız, ancak bu, sitenin belirli
          işlevselliklerinin düşmesine veya "bozulmasına" neden olabilir.
        </p>
        <h2>Çerezleri Nasıl Kullanıyoruz</h2>
        <p className="t-17">
          Çerezleri aşağıda ayrıntılı olarak belirtilen çeşitli nedenlerle
          kullanıyoruz. Ne yazık ki, çoğu durumda çerezleri devre dışı
          bırakmanın, bu siteye ekledikleri işlevselliği ve özellikleri tamamen
          devre dışı bırakmadan yapılabilecek endüstri standardı seçenekleri
          yoktur. Kullandığınız bir hizmeti sağlamak için kullanılıyor
          olabileceklerinden emin değilseniz, tüm çerezleri açık bırakmanız
          önerilir.
        </p>
        <h2>Çerezleri Devre Dışı Bırakma</h2>
        <p className="t-17">
          Çerezlerin ayarlanmasını tarayıcınızın ayarlarını değiştirerek
          engelleyebilirsiniz (bunun nasıl yapılacağıyla ilgili olarak
          tarayıcınızın Yardım bölümüne bakın). Çerezleri devre dışı bırakmanın,
          ziyaret ettiğiniz bu ve birçok diğer web sitesinin işlevselliğini
          etkileyeceğini unutmayın. Çerezleri devre dışı bırakmak, genellikle bu
          sitenin belirli işlevselliklerinin ve özelliklerinin de devre dışı
          kalmasına neden olacaktır. Bu nedenle çerezleri devre dışı
          bırakmamanız tavsiye edilir.
        </p>
        <h2>Ayarladığımız Çerezler</h2>
        <p className="t-17">
          Siteyi yapılandırmak için kullanılan çerezler. Bu sitede iyi bir
          deneyime sahip olmanızı sağlamak için, siteyi nasıl kullandığınıza
          dair tercihler ayarlamanıza olanak tanıyan bir işlev sunuyoruz.
          Tercihlerinizi hatırlamak için, bu bilgilerin, tercihlerinizi
          etkileyen bir sayfayla etkileşim kurduğunuzda hatırlanabilmesi
          amacıyla çerezler ayarlamamız gerekir.
        </p>
        <h2>Üçüncü Taraf Çerezler</h2>
        <p className="t-17">
          Bazı özel durumlarda güvenilir üçüncü taraflar tarafından sağlanan
          çerezleri de kullanıyoruz. Aşağıdaki bölüm, bu site aracılığıyla
          karşılaşabileceğiniz üçüncü taraf çerezlerini açıklamaktadır.
        </p>
        <p className="t-17">
          Bu site, siteyi nasıl kullandığınızı anlamamıza ve deneyiminizi
          geliştirme yollarını bulmamıza yardımcı olan en yaygın ve güvenilir
          analiz çözümü olan Google Analytics'i kullanmaktadır. Bu çerezler,
          sitede ne kadar zaman geçirdiğiniz ve ziyaret ettiğiniz sayfalar gibi
          bilgileri izleyebilir, böylece ilgi çekici içerikler üretmeye devam
          edebiliriz.
        </p>
        <p className="t-17">
          Google Analytics çerezleri hakkında daha fazla bilgi için resmi Google
          Analytics sayfasına bakın.
        </p>
        <h2>Daha Fazla Bilgi</h2>
        <p className="t-17">
          Umarım bu, size durumu netleştirmiştir ve daha önce belirtildiği gibi,
          bir şeye ihtiyacınız olup olmadığından emin değilseniz, genellikle
          çerezleri etkin bırakmak daha güvenlidir, çünkü sitemizde
          kullandığınız bir işlev ile etkileşime girebilir.
        </p>
      </section>
    </main>
  );
};

export default Cookies;

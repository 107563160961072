import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import About from "../About/About";
import Games from "../Games/Games";
import Faq from "../Faq/Faq";
import Reviews from "../Reviews/Reviews";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import GameOne from "../GamePages/GameOne";
import GameTwo from "../GamePages/GameTwo";
import GameThree from "../GamePages/GameThree";
import GameFour from "../GamePages/GameFour";
import GameFive from "../GamePages/GameFive";
import GameSix from "../GamePages/GameSix";
import GameSeven from "../GamePages/GameSeven";
import GameEight from "../GamePages/GameEight";
import GameNine from "../GamePages/GameNine";
import GameTen from "../GamePages/GameTen";

function App() {
  return (
    <div className="trfan-page">
      <ScrollToTop />
      <Header />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/games" element={<Games />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/crossword-cash" element={<GameOne />} />
        <Route path="/larry-king-bingo" element={<GameTwo />} />
        <Route path="/maga-bingo" element={<GameThree />} />
        <Route path="/dunk-shot" element={<GameFour />} />
        <Route path="/connect-tree" element={<GameFive />} />
        <Route path="/real-jigsaw-puzzle" element={<GameSix />} />
        <Route path="/royal-marble-master" element={<GameSeven />} />
        <Route path="/solitaire-cash" element={<GameEight />} />
        <Route path="/boost" element={<GameNine />} />
        <Route path="/block-puzzle" element={<GameTen />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

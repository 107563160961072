import React from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/navigation";
import "./Reviews.css";
import star from "../../images/star.svg";

const Reviews = () => {
  const location = useLocation();
  return (
    <section className="trfan-reviews">
      <div className="trfan-reviews__body">
        {location.pathname === "/reviews" ? (
          <h1 className="trfan-reviews__header">Yorumlar</h1>
        ) : (
          <h2 className="trfan-reviews__header">Yorumlar</h2>
        )}
        <Swiper
          modules={[Pagination, Navigation]}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={true}
          spaceBetween={20}
          loop={true}
          breakpoints={{
            749: {
              slidesPerView: 2,
              spaceBetween: 40,
            }
          }}
          className="trfan-reviews__swiper"
        >
          <SwiperSlide className="trfan-review__slide">
            <div className="trfan-review__name-container">
              <p className="t-23">Mehmet</p>
              <p className="t-17 trfan-review__rating">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="27"
                  height="27"
                />
                5.0
              </p>
            </div>
            <p className="t-17">
              Bu siteyi seviyorum! Oyunlarda yarışmayı her zaman severim ve
              burada sadece kendimi test etmekle kalmıyor, aynı zamanda ödüller
              de alıyorum!
            </p>
          </SwiperSlide>
          <SwiperSlide className="trfan-review__slide">
            <div className="trfan-review__name-container">
              <p className="t-23">Ayşe</p>
              <p className="t-17 trfan-review__rating">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="27"
                  height="27"
                />
                4.0
              </p>
            </div>
            <p className="t-17">
              eSpor hayranları için mükemmel bir site! Birkaç aydır turnuvalara
              katılıyorum ve birkaç kez kazanmayı başardım! Herkese tavsiye
              ediyorum!
            </p>
          </SwiperSlide>
          <SwiperSlide className="trfan-review__slide">
            <div className="trfan-review__name-container">
              <p className="t-23">Ali</p>
              <p className="t-17 trfan-review__rating">
                <img
                  src={star}
                  alt="star"
                  title="star"
                  width="27"
                  height="27"
                />
                5.0
              </p>
            </div>
            <p className="t-17">
              Her zaman yeni ve sıcak turnuvalar buluyorum ve lisanslı
              platformlar adil oyunu garanti ediyor.
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Reviews;

import React from "react";
import { useLocation } from "react-router-dom";
import "./Games.css";
import GameItem from "../GameItem/GameItem";
import game1 from "../../images/Crossword.webp";
import game2 from "../../images/Larry-King.webp";
import game3 from "../../images/MAGA-Bingo.webp";
import game4 from "../../images/Dunk-Shot.webp";
import game5 from "../../images/Connect-Three.webp";
import game6 from "../../images/Real-Jigsaw-Puzzle.webp";
import game7 from "../../images/Royal-Marble-Master.webp";
import game8 from "../../images/Solitaire-Cash.webp";
import game9 from "../../images/BOOST22.webp";
import game10 from "../../images/Block-Puzzle.webp";

const Games = () => {
  const location = useLocation();
  return (
    <section className="trfan-games">
      {location.pathname === "/games" ? (
        <h1 className="trfan-games__header">En iyi oyunlar</h1>
      ) : (
        <h2 className="trfan-games__header">En iyi oyunlar</h2>
      )}
      <ul className="trfan-games__list">
        <GameItem
          game={game1}
          name="Crossword Cash Win Real Prizes"
          link="/crossword-cash"
        />
        <GameItem
          game={game2}
          name="Larry King Bingo: Win Cash"
          link="/larry-king-bingo"
        />
        <GameItem game={game3} name="MAGA Bingo" link="/maga-bingo" />
        <GameItem game={game4} name="Real Money Dunk Shot" link="/dunk-shot" />
        <GameItem game={game5} name="Connect Three" link="/connect-tree" />
        <GameItem
          game={game6}
          name="Real Jigsaw Puzzle: Win Reward"
          link="/real-jigsaw-puzzle"
        />
        <GameItem
          game={game7}
          name="Royal Marble Master: Win Money"
          link="/royal-marble-master"
        />
        <GameItem game={game8} name="Solitaire Cash" link="/solitaire-cash" />
        <GameItem game={game9} name="BOOST22" link="/boost" />
        <GameItem
          game={game10}
          name="Block Puzzle: Cash Prizes"
          link="/block-puzzle"
        />
      </ul>
    </section>
  );
};

export default Games;

import React from "react";
import { Link } from "react-router-dom";

const GameItem = ({ game, name, link }) => {
  return (
    <Link to={link}>
      <li className="trfan-games__item">
        <img
          src={game}
          title={name}
          alt={name}
          width="160"
          height="159"
          className="trfan-games__img"
        />
        <p className="t-14 text-center">{name}</p>
      </li>
    </Link>
  );
};

export default GameItem;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./Faq.css";
import btn from "../../images/arrow-down.svg";

const Faq = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });

  function handleOpenFaq(e) {
    const name = e.target.name;
    setIsOpen({ ...isOpen, [name]: !isOpen[name] });
  }

  return (
    <section className="trfan-faq">
      {location.pathname === "/faq" ? (
        <h1 className="trfan-faq__header">Sık sorulan sorular</h1>
      ) : (
        <h2 className="trfan-faq__header">Sık sorulan sorular</h2>
      )}
      <ul className="trfan-faq__list">
        <li className="trfan-faq__item">
          <p className="t-23">Turnuvalara nasıl katılabilirim?</p>
          <p className={`t-17 trfan-faq__answer ${isOpen.one && "visible"}`}>
            Turnuvalara katılmak için siteye kayıt olmanız gerekmektedir. Her
            turnuvaya katılımla ilgili ayrıntılı kural ve koşulları turnuva
            sayfasında bulabilirsiniz.
          </p>
          <img
            src={btn}
            alt="arrow down"
            title="arrow down"
            width="21"
            height="8"
            className={`trfan-faq__arrow ${isOpen.one && "rotate"}`}
            name="one"
            onClick={handleOpenFaq}
          />
        </li>
        <li className="trfan-faq__item">
          <p className="t-23">Nakit ödülleri nasıl alabilirim?</p>
          <p className={`t-17 trfan-faq__answer ${isOpen.two && "visible"}`}>
            Turnuva kazananlarına nakit ödüller verilir. Ödülünüzü nasıl ve ne
            zaman alacağınıza dair bilgi turnuva bittikten sonra sağlanacaktır.
          </p>
          <img
            src={btn}
            alt="arrow down"
            title="arrow down"
            width="21"
            height="8"
            className={`trfan-faq__arrow ${isOpen.two && "rotate"}`}
            name="two"
            onClick={handleOpenFaq}
          />
        </li>
        <li className="trfan-faq__item">
          <p className="t-23">Verilerim sitede güvende mi?</p>
          <p className={`t-17 trfan-faq__answer ${isOpen.three && "visible"}`}>
            Yalnızca lisanslı platformları kullanıyoruz ve verilerinizi korumak
            için gerekli tüm güvenlik önlemlerini uyguluyoruz.
          </p>
          <img
            src={btn}
            alt="arrow down"
            title="arrow down"
            width="21"
            height="8"
            className={`trfan-faq__arrow ${isOpen.three && "rotate"}`}
            name="three"
            onClick={handleOpenFaq}
          />
        </li>
        <li className="trfan-faq__item">
          <p className="t-23">Sizinle nasıl iletişime geçebilirim?</p>
          <p className={`t-17 trfan-faq__answer ${isOpen.four && "visible"}`}>
            Herhangi bir sorunuz var mı? Web sitesindeki geri bildirim formu
            aracılığıyla bizimle iletişime geçin.
          </p>
          <img
            src={btn}
            alt="arrow down"
            title="arrow down"
            width="21"
            height="8"
            className={`trfan-faq__arrow ${isOpen.four && "rotate"}`}
            name="four"
            onClick={handleOpenFaq}
          />
        </li>
      </ul>
    </section>
  );
};

export default Faq;

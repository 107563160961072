import React from "react";
import "./About.css";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();

  return (
    <section className="trfan-about">
      <div className="trfan-about__body">
        {location.pathname === "/about-us" ? (
          <h1 className="trfan-about__header">Hakkımızda</h1>
        ) : (
          <h2 className="trfan-about__header">Hakkımızda</h2>
        )}
        <p className="t-17 text-center">
          Oyunlara ve espora tutkuyla bağlı meraklılardan oluşan bir ekibiz. Bu
          siteyi size yalnızca en sevdiğiniz oyunların keyfini çıkarma fırsatı
          vermekle kalmayıp, aynı zamanda yeni deneyimler ve gerçek nakit
          ödüller kazanma fırsatını da vermek için oluşturduk!
        </p>
        <p className="t-17 text-center">
          Lisanslı oyunların listesini genişletmek ve yüksek ödül havuzlu sıcak
          turnuvaları eklemek için sürekli çalışıyoruz. Ayrıca size en alakalı
          ve heyecan verici yarışmaları sunmak için eSpor dünyasındaki en son
          gelişmeleri takip ediyoruz.
        </p>
      </div>
    </section>
  );
};

export default About;

import React from "react";
import "./GamePage.css";
import img from "../../images/MAGA-Bingo.webp";
import stars from "../../images/stars-5.svg";
import imgGameOne from "../../images/maga-1.webp";
import imgGameTwo from "../../images/maga-2.webp";
import imgGameThree from "../../images/maga-3.webp";
import imgGameFour from "../../images/maga-4.webp";
import imgGameFive from "../../images/maga-5.webp";
import imgGameSix from "../../images/maga-6.webp";
import qr from "../../images/maga-qr.webp";

const GameThree = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="MAGA Bingo"
          alt="MAGA Bingo"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">MAGA Bingo</h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              4.6
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Nakit Ödüller</p>
            <p className="t-17">Temalı Oturumlar</p>
            <p className="t-17">Sezgisel arayüz</p>
            <p className="t-17">Oyuncu topluluğu</p>
          </div>
          <p className="trfan-game-page__right t-17">
            MAGA Bingo, klasik kumar unsurlarını, daha önce MAGA (Amerika'yı
            Yeniden Harika Yap) hareketi ile ilişkilendirilen fikir ve
            isteklerden ilham alan temalarla birleştiren benzersiz ve heyecan
            verici bir bingo oyunudur. Bu oyun sadece heyecan ve kazanma olanağı
            sunmakla kalmıyor, aynı zamanda oyuncular arasında birlik ve rekabet
            ruhunu da sunuyor.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
        <img
          src={imgGameFive}
          title="oyun anı 5"
          alt="oyun anı 5"
          width="160"
          height="284"
        />
        <img
          src={imgGameSix}
          title="oyun anı 6"
          alt="oyun anı 6"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameThree;

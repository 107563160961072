import React from "react";
import "./Main.css";
import About from "../About/About";
import Games from "../Games/Games";
import Faq from "../Faq/Faq";
import Reviews from "../Reviews/Reviews";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="trfan-main">
      {/* <MainPopup/> */}
      <section className="trfan-main__cover">
        <div className="trfan-main__body">
          <div className="trfan-main__text-container">
            <h1 className="trfan-main__header">
              Gerçek nakit ödüllerle beceri oyunlarında yarışın!
            </h1>
            <p className="t-23">
              Sıcak savaşların kızıştığı ve kazananların yeni nakit ödüllerle
              ayrıldığı siteye hoş geldiniz!
            </p>
          </div>
          <div className="trfan-main__img"></div>
        </div>
      </section>
      <About />
      <Games/>
      <Faq/>
      <Reviews/>
    </main>
  );
};

export default MainCover;

import React, { useEffect, useState } from "react";
import "./Popup.css";
import cookie from "../../images/cookie.svg";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="trfan-popup-cookie">
        <form className="trfan-popup__form">
          <p className="trfan-popup__header text-center">
            <img src={cookie} alt="cookie" title="cookie" />
            Çerez Politikası
          </p>
          <p className="trfan-popup__text t-17 text-center">
            Web sitemizdeki deneyiminizi mümkün olduğunca rahat ve yasal hale
            getirmek için çerezleri kullanıyoruz. Bu teknolojiler size
            tercihlerinize göre uyarlanmış yeni ve daha iyi hizmetler sunmamıza
            yardımcı olur.
          </p>
          <div className="trfan-popup__btn-container">
            <a href="/cookies" className="trfan-popup__btn-cookie">
              Daha fazla ayrıntı
            </a>
            <p
              className="trfan-popup__btn-cookie trfan-popup__btn-agree"
              onClick={addPopupCookie}
            >
              Onaylamak
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;

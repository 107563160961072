import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";
import logo from "../../images/logo.svg";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  function handleClick() {
    setIsActive(false);
  }

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  return (
    <div className="trfan-header__container">
      <img
        src={logo}
        alt="logo"
        title="logo"
        width="271"
        height="20"
        className="trfan-header__logo"
      />
      <div className="trfan-header__link-container">
        <Link to="/" className="trfan-header__link">
          Ana sayfa
        </Link>
        <Link to="/about-us" className="trfan-header__link">
          Hakkımızda
        </Link>
        <Link to="/games" className="trfan-header__link">
          En iyi oyunlar
        </Link>
        <Link to="/faq" className="trfan-header__link">
          Sık sorulan sorular
        </Link>
        <Link to="/reviews" className="trfan-header__link">
          Yorumlar
        </Link>
        <a
          href="/#contacts"
          className="trfan-header__link"
          onClick={(e) => {
            handleNavigation(e, "/", "contacts");
          }}
        >
          İletişim
        </a>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="trfan-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="trfan-header__link" onClick={handleClick}>
              Ana sayfa
            </Link>
            <Link
              to="/about-us"
              className="trfan-header__link"
              onClick={handleClick}
            >
              Hakkımızda
            </Link>
            <Link
              to="/games"
              className="trfan-header__link"
              onClick={handleClick}
            >
              En iyi oyunlar
            </Link>
            <Link
              to="/faq"
              className="trfan-header__link"
              onClick={handleClick}
            >
              Sık sorulan sorular
            </Link>
            <Link
              to="/reviews"
              className="trfan-header__link"
              onClick={handleClick}
            >
              Yorumlar
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;

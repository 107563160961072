import React from "react";
import "./GamePage.css";
import img from "../../images/Larry-King.webp";
import stars from "../../images/stars-5.svg";
import imgGameOne from "../../images/larry-king-1.webp";
import imgGameTwo from "../../images/larry-king-2.webp";
import imgGameThree from "../../images/larry-king-3.webp";
import imgGameFour from "../../images/larry-king-4.webp";
import imgGameFive from "../../images/larry-king-5.webp";
import imgGameSix from "../../images/larry-king-6.webp";
import qr from "../../images/larry-king-qr.webp";

const GameTwo = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Larry King Bingo: Win Cash"
          alt="Larry King Bingo: Win Cash"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">
            Larry King Bingo: Win Cash
          </h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              5.0
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Nakit Ödüller</p>
            <p className="t-17">İnteraktif deneyim</p>
            <p className="t-17">Bonuslar ve promosyonlar</p>
            <p className="t-17">Oyuncu topluluğu</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Larry King Bingo, klasik bingo oyununu gerçek nakit ödüller kazanma
            şansıyla birleştiren heyecan verici ve benzersiz bir oyun
            platformudur. Oyun, ona özel bir çekicilik ve çekicilik katan ünlü
            TV sunucusu Larry King'in katılımıyla oluşturuldu.
            <br></br>Larry King Bingo'da oyuncular, her oturumun sadece ödüller
            değil aynı zamanda çeşitli bonuslar da kazanma fırsatı sunduğu
            heyecan verici bingo oyunlarına katılabilirler. Oyunun arayüzü
            sezgiseldir ve her seviyedeki oyuncunun erişebilmesini sağlar.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
        <img
          src={imgGameFive}
          title="oyun anı 5"
          alt="oyun anı 5"
          width="160"
          height="284"
        />
        <img
          src={imgGameSix}
          title="oyun anı 6"
          alt="oyun anı 6"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameTwo;

import React from "react";
import "./GamePage.css";
import img from "../../images/block-puzzle-1.webp";
import stars from "../../images/stars-4-5.svg";
import imgGameOne from "../../images/block-puzzle-1.webp";
import imgGameTwo from "../../images/block-puzzle-2.webp";
import imgGameThree from "../../images/block-puzzle-3.webp";
import imgGameFour from "../../images/block-puzzle-4.webp";
import imgGameFive from "../../images/block-puzzle-5.webp";
import qr from "../../images/block-puzzle-qr.webp";

const GameTen = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Block Puzzle: Cash Prizes"
          alt="Block Puzzle: Cash Prizes"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">Block Puzzle: Cash Prizes</h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              4.6
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Bağımlılık yaratan oyun</p>
            <p className="t-17">Yarışmalar</p>
            <p className="t-17">Kolay başlangıç</p>
            <p className="t-17">Çeşitli Modlar</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Blok Bulmaca: Nakit Ödüller, oyuncuların yalnızca mantık
            becerilerini geliştirmekle kalmayıp aynı zamanda gerçek para
            ödülleri de kazanabilecekleri eğlenceli bir blok bulmaca oyunudur.
            Bu oyun, klasik oynanışı rekabetçi unsurlarla birleştirerek bulmaca
            ve kumar severler için ideal bir seçim haline getiriyor.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
        <img
          src={imgGameFive}
          title="oyun anı 5"
          alt="oyun anı 5"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameTen;

import React from "react";
import "./GamePage.css";
import img from "../../images/Solitaire-Cash.webp";
import stars from "../../images/stars-4.svg";
import imgGameOne from "../../images/solitaire-cash-1.webp";
import imgGameTwo from "../../images/solitaire-cash-2.webp";
import imgGameThree from "../../images/solitaire-cash-3.webp";
import imgGameFour from "../../images/solitaire-cash-4.webp";
import qr from "../../images/solitaire-cash-qr.webp";

const GameEight = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Solitaire Cash"
          alt="Solitaire Cash"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">Solitaire Cash</h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              4.3
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Nakit Turnuvalar</p>
            <p className="t-17">Klasik Oynanış</p>
            <p className="t-17">Çeşitli Modlar</p>
            <p className="t-17">Sürekli güncellemeler</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Solitaire Cash, klasik solitaire'ı rekabet etme ve gerçek para
            kazanma yeteneğiyle birleştiren eğlenceli bir kart oyunudur. Bu oyun
            hem yeni başlayanlar hem de stratejik görevleri ve heyecanı seven
            deneyimli oyuncular için uygundur.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameEight;

import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import inst from "../../images/inst.svg";
import telegram from "../../images/telegram.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="trfan-footer">
      <div className="trfan-footer__left">
        <p className="t-17 ">
          “tr-fantasy-top.com” - en iyi deneyimi yaşamanızı sağlamak için
          çerezleri kullanır. Sitede kalmanız halinde çerezlerinizi kullanmamızı
          kabul etmiş olursunuz.
        </p>
        <Link to="/cookies" className="trfan-footer__link t-17">
          Daha fazlasını öğrenin
        </Link>
      </div>
      <div className="trfan-footer__right">
        <div className="trfan-footer__social">
          <Link to="https://www.instagram.com/" target="_blank">
            <img src={inst} alt="instagram icon" title="instagram icon" />
          </Link>
          <Link to="https://web.telegram.org/" target="_blank">
            <img src={telegram} alt="telegram icon" title="telegram icon" />
          </Link>
        </div>
        <Link to="/privacy" className="trfan-footer__link t-17">
          Gizlilik Politikası
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

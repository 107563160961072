import React from "react";
import "./GamePage.css";
import img from "../../images/Real-Jigsaw-Puzzle.webp";
import stars from "../../images/stars-4.svg";
import imgGameOne from "../../images/real-jigsaw-1.webp";
import imgGameTwo from "../../images/real-jigsaw-2.webp";
import imgGameThree from "../../images/real-jigsaw-3.webp";
import imgGameFour from "../../images/real-jigsaw-4.webp";
import imgGameFive from "../../images/real-jigsaw-5.webp";
import qr from "../../images/real-jigsaw-qr.webp";

const GameSix = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Real Jigsaw Puzzle: Win Reward"
          alt="Real Jigsaw Puzzle: Win Reward"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">
            Real Jigsaw Puzzle: Win Reward
          </h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              4.0
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Gerçekçi Bulmacalar</p>
            <p className="t-17">Farklı Zorluk Seviyeleri</p>
            <p className="t-17">Tek oyunculu mod</p>
            <p className="t-17">Turnuvalar</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Real Jigsaw Puzzle: Win Reward, oyuncuların gerçek para ödülleri
            için yarışırken klasik bulmaca çözme türünün keyfini
            çıkarabilecekleri eğlenceli bir bulmaca oyunudur. Bu oyun strateji,
            sabır ve yaratıcılığı birleştirerek sadece rahatlamanıza değil aynı
            zamanda dikkatinizi ve becerilerinizi test etmenize de olanak
            sağlar.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
        <img
          src={imgGameFive}
          title="oyun anı 5"
          alt="oyun anı 5"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameSix;

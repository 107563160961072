import React from "react";
import "./GamePage.css";
import img from "../../images/Connect-Three.webp";
import qr from "../../images/connect-three-qr.webp";

const GameFive = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Connect Three"
          alt="Connect Three"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">Connect Three</h1>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Klasik mekanikler</p>
            <p className="t-17">Basit kurallar</p>
            <p className="t-17">Hızlı Turlar</p>
            <p className="t-17">Farklı modlar</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Connect Three, oyuncuların birbirinin aynı üç taştan oluşan sürekli
            bir çizgi oluşturmak amacıyla taşları tahtaya yerleştirerek
            yarıştığı, bağımlılık yaratan bir mantık oyunudur. Oyun, strateji ve
            şansı birleştirerek her oyunu benzersiz kılar.
          </p>
        </div>
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameFive;

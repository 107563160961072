import React from "react";
import "./GamePage.css";
import img from "../../images/Crossword.webp";
import stars from "../../images/stars-4-5.svg";
import imgGameOne from "../../images/crossword-1.webp";
import imgGameTwo from "../../images/crossword-2.webp";
import imgGameThree from "../../images/crossword-3.webp";
import imgGameFour from "../../images/crossword-4.webp";
import imgGameFive from "../../images/crossword-5.webp";
import qr from "../../images/crossword-qr.webp";

const GameOne = () => {
  return (
    <section className="trfan-game-page">
      <div className="trfan-game-page__top-container">
        <img
          src={img}
          title="Crossword Cash Win Real Prizes"
          alt="Crossword Cash Win Real Prizes"
          width="160"
          height="159"
        />
        <div className="trfan-game-page__header-container">
          <h1 className="trfan-game-page__header">
            Crossword Cash Win Real Prizes
          </h1>
          <div className="trfan-game-page__rating-container">
            <p className="t-17">Oran</p>
            <p className="trfan-game-page__rating">
              <img
                src={stars}
                title="stars"
                alt="stars"
                width="173"
                height="32"
              />
              4.5
            </p>
          </div>
        </div>
      </div>
      <div className="trfan-game-page__middle-container">
        <h2 className="trfan-game-page__header">Oyun özellikleri</h2>
        <div className="trfan-game-page__content">
          <div className="trfan-game-page__left">
            <p className="t-17">Günlük Bulmacalar</p>
            <p className="t-17">Zorluk seviyeleri</p>
            <p className="t-17">Turnuvalar</p>
            <p className="t-17">Liderlik Tablosu</p>
            <p className="t-17">Ödüller</p>
          </div>
          <p className="trfan-game-page__right t-17">
            Bulmaca çözmeyi sever misiniz? Bilginizi sınamak ve bunun için nakit
            ödül almak ister misiniz? O halde Crossword Cash tam size göre bir
            oyun!
            <br></br>Bu eğlenceli oyun, genel kültürden popüler kültüre kadar
            çeşitli kategorilerdeki bilginizi test edecek. Her gün, size yeni
            zorluklar ve gerçek para ödülleri kazanma şansını garanti eden yeni
            bir bulmaca çıkıyor!
            <br></br>Bugün Crossword Cash'e katılın ve gerçek ödüllerle dolu,
            zeka dolu bir eğlence dünyasını keşfedin!
          </p>
        </div>
      </div>
      <div className="trfan-game-page__images">
        <img
          src={imgGameOne}
          title="oyun anı 1"
          alt="oyun anı 1"
          width="160"
          height="284"
        />
        <img
          src={imgGameTwo}
          title="oyun anı 2"
          alt="oyun anı 2"
          width="160"
          height="284"
        />
        <img
          src={imgGameThree}
          title="oyun anı 3"
          alt="oyun anı 3"
          width="160"
          height="284"
        />
        <img
          src={imgGameFour}
          title="oyun anı 4"
          alt="oyun anı 4"
          width="160"
          height="284"
        />
        <img
          src={imgGameFive}
          title="oyun anı 5"
          alt="oyun anı 5"
          width="160"
          height="284"
        />
      </div>
      <div className="trfan-game-page__qr-container">
        <div className="trfan-game-page__qr-body">
          <div className="trfan-game-page__qr-text">
            <h2 className="trfan-game-page__header">
              Oyunu hemen telefonunuza indirin
            </h2>
            <ul className="trfan-game-page__qr-list">
              <li className="t-17">1. Telefonunuzun kamerasını açın</li>
              <li className="t-17">2. Sağdaki QR kodunu tarayın</li>
              <li className="t-17">
                3. Seçenek göründüğünde, tarayıcıyı açmak için tıklayın ve
                ardından "Al" düğmesini tıklayın.
              </li>
            </ul>
          </div>
          <img
            src={qr}
            title="qr code"
            alt="qr code"
            width="136"
            height="136"
          />
        </div>
      </div>
    </section>
  );
};

export default GameOne;
